import * as actionTypes from './actionTypes'





  export const language = (language) => {
 
switch(language) {


    case "en" : {
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_EN,
                playload:{



                    /* Buttons */

                    button_save:"Save",
                    button_sent:"Submit",
                    button_reset:"Clear",
                    button_update:"Update",
                    button_delete:"Delete",
                    button_cancel:"Cancel",
                    button_change:"Change!",
                    button_edit:"Edit",
                    button_confirm:"Yes, Confirm",



                    button_settings:"Settings",
                    button_download:"Download",
                    button_download_file:"Download File",
                    button_details:"Details",
                    button_cancel_action:"Cancel The Action",
                    button_move_to_archive:"Move To Archive",
                    button_no_cancel:"Cancel",
                    button_yes_send:"Okay, Send",
                    button_home:"Home",


             
                    text_log_title_file: "file no",
                    text_log_title_file_two: "numbered",
                    text_log_description_files_insert: "Uploaded to send.",
                    text_log_description_files_update_one: "sent the file.",
                    text_log_description_files_update_two: "canceled file.",

                    text_notifi_upload: "File Uploaded",
                    text_notifi_send: "File Sent",
                    text_notifi_cancel: "File Canceled",

                    text_notifi_title:"File Notification",
                    text_notifi_new:"New",
                    text_notifi_footer:"Read all notifications",


                    /* Order Notification */


                    text_notifi_order_title: "Credit Order Notifications",
                    text_notifi_order_new: "New",
                    text_notifi_order_footer: "View all orders",
  
                    text_notifi_new_order_did: "Ordered",
                    text_notifi_new_order: "created order #.",

    
                    /* Files */
    
                    menu_text_dashboard:"Dashboard",
                    menu_text_incoming_files:"Inbox Files",
                    menu_text_outgoing_files:"Sended Files",
                    menu_text_send_file:"Send File",
                    menu_text_special_outgoing_files:"Special File Send",
                    menu_text_files_statistics:"File Statistics",
                    menu_text_arsives:"Arsives",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Add Reseller",
                    menu_text_reseller_list:"Reseller List",
                    menu_text_reseller_groups:"Reseller Group",
                    menu_text_reseller_statistics:"Reseller Statistics",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Credit Packages",
                    menu_text_credit_order:"Credit Orders",
                    menu_text_credit_statistics:"Credit Statistics",
    
    
                    /* Services */
    
                    menu_text_add_service:"Add Services",
                    menu_text_available_services:"Available Services",
                    menu_text_service_statistics:"Service Statistics",
    
    
    
                    /* CHIPTUNING */
                    menu_text_add_vehicle:"Add Vehicle",
                    menu_text_add_brand:"Add Brand",
                    menu_text_add_model:"Add Model",
                    menu_text_add_year:"Add Year",
                    menu_text_add_engine_type:"Add Engine Type",
                    menu_text_all_list:"Add Reading Method",
                    menu_text_gearbox:"Add Gearbox",
                    menu_text_chiptuning_statistics:"Chiptuning Statistics",
    
    
                    /* Settings */
                    menu_text_support:"Support",
                    menu_text_global_settings:"General Settings",
         
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"FILES",
                    menu_text_title_reseller:"RESELLER",
                    menu_text_title_credit:"CREDIT",
                    menu_text_title_service:"SERVICES",
                    menu_text_title_chiptuning:"CHIPTUNING",
                    menu_text_title_settings:"SETTINGS",

                     /* Login Page */
                  
                    login_page_text_title :"Login",
                    login_page_text_welcome :"Welcome back, please login to your account.",
                    login_page_text_email_placeholder :"Username",
                    login_page_text_password_placeholder :"Password",
                    login_page_text_remmember :"Remember me",
                    login_page_text_registered_button :"Register",
                    login_page_text_login_button :"Login",
                    login_page_text_loading_button :"Loading...",



                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Reseller",
                   add_reseller_page_bradcom_navi_pagename :"Add Reseller",
                   add_reseller_page_bradcom_navi_formname :"Add Reseller Form",
                   add_reseller_page_bradcom_form_firstname :"First Name",
                   add_reseller_page_bradcom_form_lastname :"Last Name",
                   add_reseller_page_bradcom_form_email :"Email",
                   add_reseller_page_bradcom_form_password :"Password",
                   add_reseller_page_bradcom_form_phone :"Phone",
                   add_reseller_page_bradcom_form_address :"Address",
                   add_reseller_page_bradcom_form_company :"Company Name",
                   add_reseller_page_bradcom_form_phone:"Phone",
                   add_reseller_page_bradcom_form_country :"Country",
                   add_reseller_page_bradcom_form_city :"City",


                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"FILES",
                text_send_file_page_bradcom_navi_pagename:"Send File",
                text_send_file_page_bradcom_navi_formname:"File List",



                /* Files Page (İnbox Page) */

                text_inbox_file_page_bradcom_navi_title:"FILES",
                text_inbox_file_page_bradcom_navi_pagename:"Inbox File",
                text_inbox_file_page_bradcom_navi_formname:"File List",



                 text_send_file_page_prev:"Prev",
                 text_send_file_page_next:"Next",
                 text_send_file_page_button_okay:"Create",

                 text_send_file_page_form_title:"File Send From",
                 text_send_file_page_service_type:"Service Select",
                 text_send_file_page_service_stepone_info_box_title:"Service İnfo",
                 text_send_file_page_service_stepone_info_box_description:"Each service you select above has its own loan amount. You can check your current credit and the credit amount of the service you want to receive from the right.",
                 text_send_file_page_credit_total_title:"Credit Calculation",
                 text_send_file_page_current_credit:"Your Current Credit",
                 text_send_file_page_total_credit:"Total Service",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Vehicle',
                 text_send_file_form_step2_vehicle:'Vehicle',
                 text_send_file_form_step2_brand_select:'Brand Select',
                 text_send_file_form_step2_brand:'Brand',
                 text_send_file_form_step2_model_select:'Model Select',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Year Select',
                 text_send_file_form_step2_year:'Year',
                 text_send_file_form_step2_engine_type:'Engine Type',
                 text_send_file_form_step2_engine:'Engine',
                 text_send_file_form_step2_gearbox_type:'Gear Box Type',
                 text_send_file_form_step2_gearbox:'Gear Box',
                 

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Reading Method',
                  text_send_file_form_step3_reading_type:'Reading Type',
                  text_send_file_form_step3_milage:'Milage',
                  text_send_file_form_step3_licence_plate:'Licance Plate',
                  text_send_file_form_step3_vin_number:'Vin Number',
                  text_send_file_form_step3_dtc_list:'DTC List (Write separated by commas)',
                  text_send_file_form_step3_note:'Leave a note!',




                /* Send File Upload ( Send File Component) */


                  text_file_uplpad_labelIdle:'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
                  text_file_uplpad_labelFileLoading:'Loading',
                  text_file_uplpad_labelFileProcessing:'Uploading',
                  text_file_uplpad_labelFileProcessingComplete:'Upload complete',
                  text_file_uplpad_labelFileProcessingAborted:'Upload cancelled',
                  text_file_uplpad_labelTapToUndo:'tap to undo',
                  text_file_uplpad_labelTapToCancel:'tap to cancel',
                  text_file_uplpad_labelTapToRetry:'tap to retry',
                  text_file_uplpad_labelButtonRetryItemLoad:'Retry',
                  text_file_uplpad_labelButtonAbortItemProcessing:'Cancel',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Undo',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Retry',
                  text_file_uplpad_labelButtonProcessItem:'Upload',




                 /* Create Credit Package */ 

                 text_credit_page_bradcom_navi_title:"Credit",
                 text_credit_page_bradcom_navi_pagename:"Credit Packages",
                 text_credit_page_bradcom_navi_formname:"Credit Package Transactions ",

                 text_credit_package_credit_name:"Credit",
                 text_credit_package_add_credit_button:"New Credit Package",
                 text_credit_package_update_package:"Update Package",
                 text_credit_package_delete_package:"Delete Package",
                 text_credit_package_create_form_title:"Create Credit Package From ",
                 text_credit_package_update_from_title:"Update Credit Package From ",
                 text_credit_package_create_from_input_credit_name:"Credit Name",
                 text_credit_package_create_from_input_credit_quantity:"Credit Quantity",
                 text_credit_package_create_from_input_credit_price:"Price",
                 text_credit_package_create_from_input_credit_discount:"Discount(%)",
                 text_credit_package_create_current_discount:"Current Disocunt Rate",

                 text_credit_package_notification_delete_package:"The package was deleted successfully.",
                 text_credit_package_notification_update_package:"The package has been successfully updated.",
                 text_credit_package_notification_required_inputs:"Please fill in the required fields.",
                 text_credit_package_notification_success_form:"The package has been successfully added.",
    



                 db_text_payment_type_paypal:"PAYPAL",
                 db_text_payment_type_bank_transfer:"BANK TRANSFER",
                 db_text_payment_type_credit_card:"CREDIT CARD",



                /* Reselelr List Pages */ 
                text_reseller_list_page_bradcom_navi_title:"Reseller",
                text_reseller_list_page_bradcom_navi_pagename:"Reseller List",
                text_reseller_list_page_bradcom_navi_formname:"Reseller View",

                text_reseller_list_page_table_name:"Reseller Name",
                text_reseller_list_page_table_company_name:"Company",
                text_reseller_list_page_table_date:"Create Date",
                text_reseller_list_page_table_status:"Status",
                text_reseller_list_page_table_credit:"Credit",
                text_reseller_list_page_table_settings:"Settings",


                /* Reselelr Details Page */ 

                text_reseller_detail_page_user_info_account:"Reseller Account Info",
                text_reseller_detail_page_user_info_name:"Name Surname",
                text_reseller_detail_page_user_info_email:"E-Mail",
                text_reseller_detail_page_user_info_phone:"Phone",
                text_reseller_detail_page_user_info_company:"Company",
                text_reseller_detail_page_user_info_address:"Address",
                text_reseller_detail_page_user_info_password:"Password",
                text_reseller_detail_page_user_info_create_date:"Registered Date",
                text_reseller_detail_page_user_info_credit:"Credit",
                text_reseller_detail_page_user_info_new_credit:"New Credit",
                text_reseller_detail_page_user_info_add_credit:"Add Credit",
                text_reseller_detail_page_user_info_del_credit:"Delete Credit",
                text_reseller_detail_page_user_info_items:"items",
                text_reseller_detail_page_user_info_orders:"Orders",

                text_reseller_detail_page_user_table_tab_orders:"Orders",
                text_reseller_detail_page_user_table_tab_files:"Files",
                text_reseller_detail_page_user_table_tab_actives:"Activites",

                text_reseller_detail_page_user_table_title:"Reseller Activites",
                text_reseller_detail_page_user_table_order_no:"Order No",
                text_reseller_detail_page_user_table_name:"Name Surname",
                text_reseller_detail_page_user_table_order_quantity:"Order Quantity",
                text_reseller_detail_page_user_table_total_credit:"Total Credit",
                text_reseller_detail_page_user_table_global_total:"Global Total",
                text_reseller_detail_page_user_table_date:"Order Date",
                text_reseller_detail_page_user_table_status:"Status",
                text_reseller_detail_page_user_table_setting:"Settings",


                text_reseller_detail_page_user_table_files_no:"File No",
                text_reseller_detail_page_user_table_files_size:"File Size",
                text_reseller_detail_page_user_table_files_type:"File Type",
                text_reseller_detail_page_user_table_files_download_link:"Download Link",
                text_reseller_detail_page_user_table_files_update_date:"Upload Date",
                text_reseller_detail_page_user_table_files_update_time:"Upload Time",
                text_reseller_detail_page_user_table_files_status:"Status",
                text_reseller_detail_page_user_table_files_setting:"Settings",

                
                text_reseller_order_table_order_status_finished:"SUCCESS",
                text_reseller_order_table_order_status_canceled:"CANCELED",
                text_reseller_order_table_order_status_pending:"PENDING",

                text_reseller_order_table_files_status_finished:"FINISHED",
                text_reseller_order_table_files_status_proccess:"PROCCESS",
                text_reseller_order_table_files_status_canceled:"CANCELED",
                text_reseller_order_table_files_status_pending:"PENDING",


               /* Reselelr Invoice Page */ 
               
               text_reseller_order_invoice_bradcom_navi_title:"Reseller",
               text_reseller_order_invoice_bradcom_navi_pagename:"Orders",
               text_reseller_order_invoice_bradcom_navi_formname:"Invoice",

                text_reseller_order_invoice_mail:"Send Mail",
                text_reseller_order_invoice_print:"Print",
                text_reseller_order_invoice_approve:"Approve",
                text_reseller_order_invoice_invoice:"Invoice",
                text_reseller_order_invoice_invoice_no:"Invoice No",
                text_reseller_order_invoice_invoice_date:"Invoice Date",

                text_reseller_order_invoice_package_name:"Credit Package Name",
                text_reseller_order_invoice_credit:"Credit",
                text_reseller_order_invoice_quantity:"Quantity",
                text_reseller_order_invoice_price:"Price",
                text_reseller_order_invoice_total:"Total",
                text_reseller_order_invoice_suptotal:"SupTotal",
                text_reseller_order_invoice_discount:"Discount",
                text_reseller_order_invoice_global_total:"Global Total",


                text_reseller_order_invoice_footer_description:"Transfer the amounts to the business amount below. Please include invoice number on your check.",
                text_reseller_order_invoice_bank_name:"BANK",
                text_reseller_order_invoice_bank_account_name:"ACCOUNT NAME",
                text_reseller_order_invoice_bank_account_code:"ACCOUNT CODE",
                text_reseller_order_invoice_bank_iban:"IBAN",
                text_reseller_order_invoice_bank_branch_no:"BRANCH NO",
                text_reseller_order_invoice_bank_swift_code:"SWIFT CODE",
                text_reseller_order_invoice_bank_description:"BANK DESCRIPTION",



                 /* Bank Add and Edit Page */ 

                 text_bank_add_end_edit_page_bradcom_navi_one:"Banks",
                 text_bank_add_end_edit_page_bradcom_navi_two:"Bank List",
                 text_bank_add_end_edit_page_bradcom_navi_tree:"Bank Add And Edit",
  
                 text_bank_add_end_edit_page_add_from_title:"New Bank Add",
                 text_bank_add_end_edit_page_edit_from_title:"Bank Update Form",




                /* Credit Order Modal Confirm */ 

                text_credit_order_table_confirm_model_question:"Do you want to confirm the order ?",
                text_credit_order_table_confirm_model_note:"After confirming, you can cancel it back.",
                text_credit_order_table_confirm_model_okay_title:"Wonderful",
                text_credit_order_table_confirm_model_okay:"Congratulations, order confirmed :)",
                text_credit_order_table_confirm_model_cancel_title:"Canceled!", 
                text_credit_order_table_confirm_model_cancel:"Approval canceled",               



                text_credit_order_table_delete_model_question:"Do you want to cancel the order ?",
                text_credit_order_table_delete_model_note:"You can confirm back after canceling",
                text_credit_order_table_delete_model_okay_title:"Unfortunately",
                text_credit_order_table_delete_model_okay:"Boss, order canceled :(",
                text_credit_order_table_delete_model_cancel_title:"Stopped!", 
                text_credit_order_table_delete_model_cancel:"Cancellation stopped :)",  



                 /* Credit Order Page */ 

                 text_credit_order_page_bradcom_navi_title:"Orders",
                 text_credit_order_page_bradcom_navi_pagename:"Orders Settings",
                 text_credit_order_page_bradcom_navi_endName:"Order List",
                 
                 text_credit_order_page_card_title:"Order List",

                 text_credit_order_page_user_table_order_no:"Order No",
                 text_credit_order_page_user_table_name:"Name Surname",
                 text_credit_order_page_user_table_order_quantity:"Order Quantity",
                 text_credit_order_page_user_table_total_credit:"Total Credit",
                 text_credit_order_page_user_table_global_total:"Global Total",
                 text_credit_order_page_user_table_date:"Order Date",
                 text_credit_order_page_user_table_status:"Status",
                 text_credit_order_page_user_table_setting:"Settings",

                 text_credit_order_table_order_status_finished:"SUCCESS",
                 text_credit_order_table_order_status_canceled:"CANCELED",
                 text_credit_order_table_order_status_pending:"PENDING",



                /* Services Modal Confirm */ 

                text_credit_order_table_services_model_question:"Do you want to delete the service?",
                text_credit_order_table_services_model_note:"You cannot undo after deleting",
                text_credit_order_table_services_model_okay_title:"Completed",
                text_credit_order_table_services_model_okay:"Service Deleted",
                text_credit_order_table_services_model_cancel_title:"Cancel!", 
                text_credit_order_table_services_model_cancel:"Deletion canceled ",   
                
      
                
                /* Services Page*/ 


                text_services_page_bradcom_navi_title:"Services",
                text_services_page_bradcom_navi_pagename:"Service Settings",
                text_services_page_bradcom_navi_endName:"Service List",

                text_services_page_button_add_service:"Add Service",
                text_services_page_card_title:"Service List",
                text_services_page_credit:"Credit",
                text_services_page_discount:"Discount",
                text_services_page_description:"Description",
                text_services_page_date:"Create Date",

                text_add_services_page_form_creare_form_title:"Add New Service Form",
                text_add_services_page_form_update_form_title:"Update Service Form",
                text_add_services_page_form_services_name:"Service Name",
                text_add_services_page_form_credit:"Service Credit Fee",
                text_add_services_page_form_discount:"Discount",
                text_add_services_page_form_description:"Description",




                 text_page_support_create_support:"Create Ticket",
                 text_page_support_inbox:"Inbox",
                 text_page_support_sent:"Send",
                 text_page_support_draft:"Draft",
                 text_page_support_trash:"Trash",
                 text_page_support_Standart:"Standart",
                 text_page_support_Normaly:"Important",
                 text_page_support_Emegency:"Emergency",
                 text_page_support_Private:"Private",

                 text_page_support_select_all:"Select All",
                 text_page_support_search_bar:"Search",
                 text_page_support_labels:"Labels",
                 
                 text_page_support_form_title:"Create New Ticket",
                 text_page_support_form_users:"Resellers",
                 text_page_support_form_subject:"Subject",
                 text_page_support_form_attacment:"ATTACHEMENTS",
                 text_page_support_form_item_not_found:"Wonderful. No one has the Problem of Today :) ",
                 text_page_support_form_Answer:"Answer",


                 
                 text_file_archives_page_bradcom_navi_title:"Files",
                 text_file_archives_page_bradcom_navi_pagename:"File Archives",
                 text_file_archives_page_bradcom_navi_list:"Arhives List",

    

                   /* Files  Global*/
                  
                   text_files_file_no:"Files No",

                }
              });
      
        };

    }


    case "tr" : {
        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",
                    button_cancel:"İptal",
                    button_change:"Değiştir",
                    button_edit:"Düzenle",
                    button_confirm:"Evet, Onaylıyorum",

                    button_move_to_archive:"Arşive Taşı",




                    button_settings:"Ayarlar",
                    button_download:"İndr",
                    button_download_file:"Dosya'yı İndir",
                    button_details:"Detaylar",
                    button_cancel_action:"İşlemi İptal Et",

                   /* Files  Global*/
                  
                   text_files_file_no:"Dosya No",


                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
                    menu_text_reseller_list:"Bayi Listesi",
                    menu_text_reseller_groups:"Bayi Grupları",
                    menu_text_reseller_statistics:"Bayi İstatistikleri",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                    menu_text_credit_statistics:"Kredi İstatistikleri",
    
    
                    /* Services */
    
                    menu_text_add_service:"Hizmet Ekle",
                    menu_text_available_services:"Mevcut Hizmetler",
                    menu_text_service_statistics:"Hizmet İstatistikleri",
    
    
    
                    /* CHIPTUNING */
                    menu_text_add_vehicle:"Araç Tipi Ekle",
                    menu_text_add_brand:"Marka Ekle",
                    menu_text_add_model:"Model ekle",
                    menu_text_add_year:"Yıl Ekle",
                    menu_text_add_engine_type:"Motor Tipi Ekle",
                    menu_text_all_list:"Add Reading Method",
                    menu_text_gearbox:"Vites Tipi Ekle",
                    menu_text_chiptuning_statistics:"Chiptuning İstatistikleri",
    
    
                    /* Settings */
                    menu_text_support:"Destek",
                    menu_text_global_settings:"Genel Ayarlar",
    
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                    menu_text_title_reseller:"BAYİ YÖNETİMİ",
                    menu_text_title_credit:"KREDİ YÖNETİMİ",
                    menu_text_title_service:"SERVİS YÖNETİMİ",
                    menu_text_title_chiptuning:"CHIPTUNING YÖNETİMİ",
                    menu_text_title_settings:"AYARLAR",

                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_phone:"Telefon",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",

                /* Files Page ( Send File Page) */

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme ",



                /* Files Page (İnbox Page) */

                text_inbox_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_inbox_file_page_bradcom_navi_pagename:"Dosya Listesi",
                text_inbox_file_page_bradcom_navi_formname:"Gelen Dosyalar",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',

                
                  /* Send File Upload ( Send File Component) */

             

                  text_file_uplpad_labelIdle:'Dosya yı tutarak buraya bırakabilirsiniz veya <span class="filepond--label-action"> Bilgisayardan Seç </span>',
                  text_file_uplpad_labelFileLoading:'Bekleyin',
                  text_file_uplpad_labelFileProcessing:'Dosya yükleniyor lütfen bekleyin.',
                  text_file_uplpad_labelFileProcessingComplete:'Yükleme tamamlandı.',
                  text_file_uplpad_labelFileProcessingAborted:'Yükleme iptal edildi.',
                  text_file_uplpad_labelTapToUndo:'Yüklemeyi geri al!',
                  text_file_uplpad_labelTapToCancel:'İptal et',
                  text_file_uplpad_labelTapToRetry:'Tekrar denemek için tıkla ',
                  text_file_uplpad_labelButtonRetryItemLoad:'Yeniden dene',
                  text_file_uplpad_labelButtonAbortItemProcessing:'İptal et',
                  text_file_uplpad_labelButtonUndoItemProcessing:'Geri al',
                  text_file_uplpad_labelButtonRetryItemProcessing:'Yeniden dene',
                  text_file_uplpad_labelButtonProcessItem:'Yükle',



                 /* Create Credit Package */ 
                 text_credit_page_bradcom_navi_title:"Kredi Yönetimi",
                 text_credit_page_bradcom_navi_pagename:"Kredi Paketleri",
                 text_credit_page_bradcom_navi_formname:"Kredi Paket İşlemleri",

                 text_credit_package_credit_name:"Kredi",
                 text_credit_package_add_credit_button:"Yeni Kredi Paketi",
                 text_credit_package_update_package:"Paketi Güncelle",
                 text_credit_package_delete_package:"Paketi Sil",
                 text_credit_package_create_form_title:"Kredi Paket Oluşturma Formu",
                 text_credit_package_update_from_title:"Kredi Paket Güncellme Formu",
                 text_credit_package_create_from_input_credit_name:"Kredi Paket Adı",
                 text_credit_package_create_from_input_credit_quantity:"Kredi Miktarı",
                 text_credit_package_create_from_input_credit_price:"Fiyatı",
                 text_credit_package_create_from_input_credit_discount:"İndirim(%)",
                 text_credit_package_create_current_discount:"Mevcut İndirim Oranı",

                 text_credit_package_notification_delete_package:"Paket başarılı bir şekilde silindi.",
                 text_credit_package_notification_update_package:"Paket başarılı bir şekilde güncellendi.",
                 text_credit_package_notification_required_inputs:"Lütfen gerekli alanları doldurun.",
                 text_credit_package_notification_success_form:"Paket başarılı bir şekilde eklendi.",

              
                 
                 db_text_payment_type_paypal:"PAYPAL",
                 db_text_payment_type_bank_transfer:"HAVALE / EFT",
                 db_text_payment_type_credit_card:"KREDİ KARTI", 




                /* Reselelr List Pages */ 
                text_reseller_list_page_bradcom_navi_title:"Bayi Yönetimi",
                text_reseller_list_page_bradcom_navi_pagename:"Bayiler",
                text_reseller_list_page_bradcom_navi_formname:"Bayi Listesi",

                text_reseller_list_page_table_name:"Bayi Adı",
                text_reseller_list_page_table_company_name:"Şirket",
                text_reseller_list_page_table_date:"Kayıt Tarihi",
                text_reseller_list_page_table_status:"Durum",
                text_reseller_list_page_table_credit:"Kredi",
                text_reseller_list_page_table_settings:"Ayarlar",

                /* Reselelr Details Page */ 

                text_reseller_detail_page_user_info_account:"Bayi Hesabı Bilgisi",
                text_reseller_detail_page_user_info_name:"Adı Soyadı",
                text_reseller_detail_page_user_info_email:"Eposta",
                text_reseller_detail_page_user_info_phone:"Telefon",
                text_reseller_detail_page_user_info_company:"Şirket",
                text_reseller_detail_page_user_info_address:"Adres",
                text_reseller_detail_page_user_info_password:"Şifre",
                text_reseller_detail_page_user_info_create_date:"Kayıt Tarihi",
                text_reseller_detail_page_user_info_credit:"Kredi",
                text_reseller_detail_page_user_info_new_credit:"Yeni Kredi",
                text_reseller_detail_page_user_info_add_credit:"Kredi Ekle",
                text_reseller_detail_page_user_info_del_credit:"Kredi Sil",
                text_reseller_detail_page_user_info_items:"Adet",
                text_reseller_detail_page_user_info_orders:"Sipariş",

                text_reseller_detail_page_user_table_tab_orders:"Siparişler",
                text_reseller_detail_page_user_table_tab_files:"Dosyalar",
                text_reseller_detail_page_user_table_tab_actives:"Aktiviteler",

                text_reseller_detail_page_user_table_title:"Bayi Aktiviteleri",
                text_reseller_detail_page_user_table_order_no:"Sipariş No",
                text_reseller_detail_page_user_table_name:"Ad Soyad",
                text_reseller_detail_page_user_table_order_quantity:"Sipariş Adeti",
                text_reseller_detail_page_user_table_total_credit:"Toplam Kredi",
                text_reseller_detail_page_user_table_global_total:"Toplam Tutar",
                text_reseller_detail_page_user_table_date:"Sipariş Tarihi",
                text_reseller_detail_page_user_table_status:"Durum",
                text_reseller_detail_page_user_table_setting:"Ayarlar",

                text_reseller_detail_page_user_table_files_no:"Dosya No",
                text_reseller_detail_page_user_table_files_size:"Dosya Boyutu",
                text_reseller_detail_page_user_table_files_type:"Dosya Tipi",
                text_reseller_detail_page_user_table_files_download_link:"İndirme Bağlantısı",
                text_reseller_detail_page_user_table_files_update_date:"Yükleme Tarihi",
                text_reseller_detail_page_user_table_files_update_time:"Yükleme Saati",
                text_reseller_detail_page_user_table_files_status:"Durum",
                text_reseller_detail_page_user_table_files_setting:"Ayarlar",

                text_reseller_order_table_order_status_finished:"TAMAMLANDI",
                text_reseller_order_table_order_status_canceled:"İPTAL EDİLDİ",
                text_reseller_order_table_order_status_pending:"BEKLİYOR",

                text_reseller_order_table_files_status_finished:"TAMAMLANDI",
                text_reseller_order_table_files_status_proccess:"İŞLENİYOR",
                text_reseller_order_table_files_status_canceled:"İPTAL EDİLDİ",
                text_reseller_order_table_files_status_pending:"BEKLİYOR",

               
               /* Reselelr Invoice Page */ 
               
                text_reseller_order_invoice_bradcom_navi_title:"Bayiler",
                text_reseller_order_invoice_bradcom_navi_pagename:"Siparişler",
                text_reseller_order_invoice_bradcom_navi_formname:"Fatura",

                text_reseller_order_invoice_mail:"Mail Gönder",
                text_reseller_order_invoice_print:"Yazdır",
                text_reseller_order_invoice_approve:"Onayla",
                text_reseller_order_invoice_invoice:"Fatura",
                text_reseller_order_invoice_invoice_no:"Fatura No",
                text_reseller_order_invoice_invoice_date:"Fatura Tarih",

                text_reseller_order_invoice_package_name:"Kredi Paket Adı",
                text_reseller_order_invoice_credit:"Kredi",
                text_reseller_order_invoice_quantity:"Adet",
                text_reseller_order_invoice_price:"Fiyat",
                text_reseller_order_invoice_total:"Toplam",
                text_reseller_order_invoice_suptotal:"TOPLAM",
                text_reseller_order_invoice_discount:"İNDİRİM",
                text_reseller_order_invoice_global_total:"GENEL TOPLAM",


                text_reseller_order_invoice_footer_description:"Yukarda belirtilen tutarı altta belirtilen banka hesabına gönderebilirsiniz. Lütfen fatura no'sunu transfer açıklamasına yazın.",
                text_reseller_order_invoice_bank_name:"Banka",
                text_reseller_order_invoice_bank_account_name:"Müşteri No",
                text_reseller_order_invoice_bank_account_code:"Hesap No",
                text_reseller_order_invoice_bank_iban:"IBAN",
                text_reseller_order_invoice_bank_branch_no:"Şube Kodu",
                text_reseller_order_invoice_bank_swift_code:"Swfit Kodu",
                text_reseller_order_invoice_bank_description:"Açıklama",


                /* Bank Add and Edit Page */ 

                text_bank_add_end_edit_page_bradcom_navi_one:"Bankalar",
                text_bank_add_end_edit_page_bradcom_navi_two:"Banka Liste",
                text_bank_add_end_edit_page_bradcom_navi_tree:"Banka Ekleme ve Düzeltme",

                text_bank_add_end_edit_page_add_from_title:"Yeni Banka Ekle",
                text_bank_add_end_edit_page_edit_from_title:"Banka Güncelleme Formu",



                /* Credit Order Modal Confirm */ 

                text_credit_order_table_confirm_model_question:"Siparişi onaylamak istiyor musun?",
                text_credit_order_table_confirm_model_note:"Onayladıktan sonra geri iptal edebilirsiniz.",
                text_credit_order_table_confirm_model_okay_title:"Harika",
                text_credit_order_table_confirm_model_okay:"Tebrikler, sipariş onaylandı :)",
                text_credit_order_table_confirm_model_cancel_title:"İptal!", 
                text_credit_order_table_confirm_model_cancel:"Onaylama iptal edildi",               



                text_credit_order_table_delete_model_question:"Siparişi iptal etmek istiyor musun?",
                text_credit_order_table_delete_model_note:"İptal ettikten sonra geri onaylıya bilirsiniz",
                text_credit_order_table_delete_model_okay_title:"Maalesef",
                text_credit_order_table_delete_model_okay:"Patron, sipariş iptal edildi :(",
                text_credit_order_table_delete_model_cancel_title:"Durduruldu!", 
                text_credit_order_table_delete_model_cancel:"İptal etme durduruldu :)",  



                 /* Credit Order Page */ 

                 text_credit_order_page_bradcom_navi_title:"Siparişler",
                 text_credit_order_page_bradcom_navi_pagename:"Sipariş İşlemleri",
                 text_credit_order_page_bradcom_navi_endName:"Sipariş Listesi",
                 
                 text_credit_order_page_card_title:"Sipariş Listesi",

                 text_credit_order_page_user_table_order_no:"Sipariş No",
                 text_credit_order_page_user_table_name:"Ad Soyad",
                 text_credit_order_page_user_table_order_quantity:"Sipariş Adeti",
                 text_credit_order_age_user_table_total_credit:"Toplam Kredi",
                 text_credit_order_page_user_table_global_total:"Toplam Tutar",
                 text_credit_order_page_user_table_date:"Sipariş Tarihi",
                 text_credit_order_page_user_table_status:"Durum",
                 text_credit_order_page_user_table_setting:"Ayarlar",

                 text_credit_order_table_order_status_finished:"TAMAMLANDI",
                 text_credit_order_table_order_status_canceled:"İPTAL EDİLDİ",
                 text_credit_order_table_order_status_pending:"BEKLİYOR",


              /* Services Modal Confirm */ 

              text_credit_order_table_services_model_question:"Hizmeti silmek istiyor musunuz?",
              text_credit_order_table_services_model_note:"Sildikten sonra geri alamazsınız",
              text_credit_order_table_services_model_okay_title:"Tamamlandı",
              text_credit_order_table_services_model_okay:"Hizmet Silindi",
              text_credit_order_table_services_model_cancel_title:"İptal!", 
              text_credit_order_table_services_model_cancel:"Silme işlemi iptal edildi",     




                
                /* Services Page*/ 


                text_services_page_bradcom_navi_title:"HizmetYönetimi",
                text_services_page_bradcom_navi_pagename:"Hizmet Ayarları",
                text_services_page_bradcom_navi_endName:"Hizmet Listesi",

                text_services_page_button_add_service:"Yeni Hizmet Ekle",
                text_services_page_card_title:"Hizmet Listesi",
                text_services_page_credit:"Kredi",
                text_services_page_discount:"İndirim",
                text_services_page_description:"Açıklama",
                text_services_page_date:"Oluşturma Tarihi",

                text_add_services_page_form_creare_form_title:"Yeni Hizmet Ekleme Formu",
                text_add_services_page_form_update_form_title:"Hizmet Güncelleme Formu",
                text_add_services_page_form_services_name:"Hizmet Adı",
                text_add_services_page_form_credit:"Hizmet Kredi Bedeli",
                text_add_services_page_form_discount:"İndirim",
                text_add_services_page_form_description:"Açıklama",




                 text_page_support_create_support:"Destek Oluştur",
                 text_page_support_inbox:"Gelen",
                 text_page_support_sent:"Giden",
                 text_page_support_draft:"Taslak",
                 text_page_support_trash:"Çöp Kutusu",
                 text_page_support_Standart:"Standart",
                 text_page_support_Normaly:"Önemli",
                 text_page_support_Emegency:"Acil",
                 text_page_support_Private:"Özel",

                 text_page_support_select_all:"Tümünü Seç",
                 text_page_support_search_bar:"Arama",
                 text_page_support_labels:"Etiketler",
                 
                 text_page_support_form_title:"Yeni Destek Oluştur",
                 text_page_support_form_users:"Kullanıcılar",
                 text_page_support_form_subject:"Konu",
                 text_page_support_form_attacment:"Ekler",
                 text_page_support_form_item_not_found:"İyi haber. Buğün Sorunu olan yok :) ",
                 text_page_support_form_Answer:"Cevap",
                 

                   /* File Notification */ 


                 text_log_title_file:" no'lu dosya'yı ",
                 text_log_title_file_two:" no'lu ",
                 text_log_description_files_insert:" göndermek üzere yükledi.",
                 text_log_description_files_update_one:"  dosya'yı gönderdi.",
                 text_log_description_files_update_two:" dosya'yı iptal etti.",

                 text_notifi_upload:"Dosya Yükledi",
                 text_notifi_send:"Dosya Gönderdi",
                 text_notifi_cancel:"Dosya İptel Etti ",


                 text_notifi_title:"Dosya Bildirimleri",
                 text_notifi_new:"Yeni",
                 text_notifi_footer:"Tüm bldirimleri görüntüle",




                  /* Order Notification */ 


                 text_notifi_order_title:"Kredi Sipariş Bildirimleri",
                 text_notifi_order_new:"Yeni",
                 text_notifi_order_footer:"Tüm siparişleri görüntüle",

                 text_notifi_new_order_did:"Sipariş Verdi",
                 text_notifi_new_order:"no'lu siparişi oluşturdu.",

  

                 text_file_archives_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_file_archives_page_bradcom_navi_pagename:"Arşivler",
                 text_file_archives_page_bradcom_navi_list:"Arşiv Listesi",



                }
              });
      
        };
    }

     default : {

        return  dispatch => {
            dispatch({
                type:actionTypes.LANGUAGE_TR,
                playload:{

                    /* Buttons */

        
                    button_save:"Kaydet",
                    button_sent:"Gönder",
                    button_reset:"Temizle",
                    button_update:"Güncelle",
                    button_delete:"Sil",
                    button_cancel:"İptal",
                    button_change:"Değiştir",
                    button_edit:"Düzenle",
                    button_confirm:"Evet, Onaylıyorum",
                    button_move_to_archive:"Arşive Taşı",



                    button_settings:"Ayarlar",
                    button_download:"İndr",
                    button_download_file:"Dosya'yı İndir",
                    button_details:"Detaylar",
                    button_cancel_action:"İşlemi İptal Et",

                   /* Files  Global*/
                  
                   text_files_file_no:"Dosya No",


                    /* Files */
    
                    menu_text_dashboard:"Gösterge Paneli",
                    menu_text_incoming_files:"Gelen Dosyalar",
                    menu_text_outgoing_files:"Gönderilen Dosyalar",
                    menu_text_send_file:"Dosya Gönder",
                    menu_text_special_outgoing_files:"Özel Dosya Gönder",
                    menu_text_files_statistics:"Dosya İstatistikleri",
                    menu_text_arsives:"Arşiv",
                
                    /* Customer */
    
                    menu_text_add_reseller:"Bayi Ekle",
                    menu_text_reseller_list:"Bayi Listesi",
                    menu_text_reseller_groups:"Bayi Grupları",
                    menu_text_reseller_statistics:"Bayi İstatistikleri",
      
           
                   
                    /* Credit */
    
                    menu_text_credit_package:"Kredi Paketleri",
                    menu_text_credit_order:"Kredi Siparişleri",
                    menu_text_credit_statistics:"Kredi İstatistikleri",
    
    
                    /* Services */
    
                    menu_text_add_service:"Hizmet Ekle",
                    menu_text_available_services:"Mevcut Hizmetler",
                    menu_text_service_statistics:"Hizmet İstatistikleri",
    
    
    
                    /* CHIPTUNING */
                    menu_text_add_vehicle:"Araç Tipi Ekle",
                    menu_text_add_brand:"Marka Ekle",
                    menu_text_add_model:"Model ekle",
                    menu_text_add_year:"Yıl Ekle",
                    menu_text_add_engine_type:"Motor Tipi Ekle",
                    menu_text_all_list:"Add Reading Method",
                    menu_text_gearbox:"Vites Tipi Ekle",
                    menu_text_chiptuning_statistics:"Chiptuning İstatistikleri",
    
    
                    /* Settings */
                    menu_text_support:"Destek",
                    menu_text_global_settings:"Genel Ayarlar",
    
                    /* Menü Title */
    
                    menu_text_title_file:"DOSYA YÖNETİMİ",
                    menu_text_title_reseller:"BAYİ YÖNETİMİ",
                    menu_text_title_credit:"KREDİ YÖNETİMİ",
                    menu_text_title_service:"SERVİS YÖNETİMİ",
                    menu_text_title_chiptuning:"CHIPTUNING YÖNETİMİ",
                    menu_text_title_settings:"AYARLAR",

                   /* Login Page*/

                   login_page_text_title :"Panel Giriş",
                   login_page_text_welcome :"Tekrar hoş geldiniz, lütfen hesabınıza giriş yapın.",
                   login_page_text_email_placeholder :"E-Posta adresiniz",
                   login_page_text_password_placeholder :"Şifreniz",
                   login_page_text_remmember :"Beni Hatırla",
                   login_page_text_registered_button :"Kayıt Ol",
                   login_page_text_login_button :"Giriş",
                   login_page_text_loading_button :"Yükleniyor...",

                    
                   /* Reseller Page (Add Reseller Page) */


                   add_reseller_page_bradcom_navi_title :"Bayi Yönetimi",
                   add_reseller_page_bradcom_navi_pagename :"Bayi Ekle",
                   add_reseller_page_bradcom_navi_formname :" Bayi Ekleme Formu",
                   add_reseller_page_bradcom_form_firstname :"İsim",
                   add_reseller_page_bradcom_form_lastname :"Soyisim",
                   add_reseller_page_bradcom_form_email :"E-Posta",
                   add_reseller_page_bradcom_form_password :"Şifre",
                   add_reseller_page_bradcom_form_phone :"Telefon",
                   add_reseller_page_bradcom_form_company :"Şirket Adı",
                   add_reseller_page_bradcom_form_phone:"Telefon",
                   add_reseller_page_bradcom_form_address :"Adres",
                   add_reseller_page_bradcom_form_country :"Ülke",
                   add_reseller_page_bradcom_form_city :"Şehir",


                 /* Files Page ( Send File Page) */
             

                text_send_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_send_file_page_bradcom_navi_pagename:"Dosya Gönder",
                text_send_file_page_bradcom_navi_formname:"Dosya Gönderme",


                /* Files Page (İnbox Page) */

                text_inbox_file_page_bradcom_navi_title:"Dosya Yönetimi",
                text_inbox_file_page_bradcom_navi_pagename:"Dosya Listesi",
                text_inbox_file_page_bradcom_navi_formname:"Gelen Dosyalar",


                 text_send_file_page_prev:"Geri",
                 text_send_file_page_next:"Devam",
                 text_send_file_page_button_okay:"Hizmeti Oluştur",

                 text_send_file_page_form_title:"Dosya Gönderme Formu",
                 text_send_file_page_service_type:"Hizmet Seçiniz",
                 text_send_file_page_service_stepone_info_box_title:"Hizmet Ekstra Bilgi",
                 text_send_file_page_service_stepone_info_box_description:"Yukarıda şaçmiş olduğunuz her hizmetin kendine ait bir kredi tutarı vardır. Şuan mevcut olan kredinizi ve almak istediğiniz hizmetin kredi tutarını sağ taraftan kontrol edebilirsiniz.",
                 text_send_file_page_credit_total_title:"Kredi Hesaplama",
                 text_send_file_page_current_credit:"Mevcut Krediniz",
                 text_send_file_page_total_credit:"Hiztmetlerin Toplamı",

                /* Send File Form Step2 ( Send File Page) */

                 text_send_file_form_step2_vehicle_title:'Taşıt Tipi',
                 text_send_file_form_step2_vehicle:'Taşıt',
                 text_send_file_form_step2_brand_select:'Marka Şeç',
                 text_send_file_form_step2_brand:'Marka',
                 text_send_file_form_step2_model_select:'Model Seç',
                 text_send_file_form_step2_model:'Model',
                 text_send_file_form_step2_year_select:'Yıl Seç',
                 text_send_file_form_step2_year:'Yıl',
                 text_send_file_form_step2_engine_type:'Motor Türü',
                 text_send_file_form_step2_engine:'Motor',
                 text_send_file_form_step2_gearbox_type:'Vites Türü',
                 text_send_file_form_step2_gearbox:'Vites',
                 text_send_file_form_step3_:'',

                  /* Send File Form Step3( Send File Page) */

                  text_send_file_form_step3_reading_method_select:'Yazma Türü Seçiniz',
                  text_send_file_form_step3_reading_type:'Yazma Türü',
                  text_send_file_form_step3_milage:'Kilometre',
                  text_send_file_form_step3_licence_plate:'Plaka',
                  text_send_file_form_step3_vin_number:'Şasi No',
                  text_send_file_form_step3_dtc_list:'DTC Liste (Virgül ile ayırarak yazınız)',
                  text_send_file_form_step3_note:'Bir not bırakın!',



                 /* Create Credit Package */ 


                text_credit_page_bradcom_navi_title:"Kredi Yönetimi",
                text_credit_page_bradcom_navi_pagename:"Kredi Paketleri",
                text_credit_page_bradcom_navi_formname:"Kredi Paket İşlemleri",

                 text_credit_package_credit_name:"Kredi",
                 text_credit_package_add_credit_button:"Yeni Kredi Paketi",
                 text_credit_package_update_package:"Paketi Güncelle",
                 text_credit_package_delete_package:"Paketi Sil",
                 text_credit_package_create_form_title:"Kredi Paket Oluşturma Formu",
                 text_credit_package_update_from_title:"Kredi Paket Güncellme Formu",
                 text_credit_package_create_from_input_credit_name:"Kredi Paket Adı",
                 text_credit_package_create_from_input_credit_quantity:"Kredi Miktarı",
                 text_credit_package_create_from_input_credit_price:"Fiyatı",
                 text_credit_package_create_from_input_credit_discount:"İndirim(%)",
                 text_credit_package_create_current_discount:"Mevcut İndirim Oranı",

                 text_credit_package_notification_delete_package:"Paket başarılı bir şekilde silindi.",
                 text_credit_package_notification_update_package:"Paket başarılı bir şekilde güncellendi.",
                 text_credit_package_notification_required_inputs:"Lütfen gerekli alanları doldurun.",
                 text_credit_package_notification_success_form:"Paket başarılı bir şekilde eklendi.",







                 db_text_payment_type_paypal:"PAYPAL",
                 db_text_payment_type_bank_transfer:"HAVALE / EFT",
                 db_text_payment_type_credit_card:"KREDİ KARTI",


                /* Reselelr List Pages */ 
                text_reseller_list_page_bradcom_navi_title:"Bayi Yönetimi",
                text_reseller_list_page_bradcom_navi_pagename:"Bayiler",
                text_reseller_list_page_bradcom_navi_formname:"Bayi Listesi",

                text_reseller_list_page_table_name:"Bayi Adı",
                text_reseller_list_page_table_company_name:"Şirket",
                text_reseller_list_page_table_date:"Kayıt Tarihi",
                text_reseller_list_page_table_status:"Durum",
                text_reseller_list_page_table_credit:"Kredi",
                text_reseller_list_page_table_settings:"Ayarlar",

              

                /* Reselelr Details Page */ 

                text_reseller_detail_page_user_info_account:"Bayi Hesabı Bilgisi",
                text_reseller_detail_page_user_info_name:"Adı Soyadı",
                text_reseller_detail_page_user_info_email:"Eposta",
                text_reseller_detail_page_user_info_phone:"Telefon",
                text_reseller_detail_page_user_info_company:"Şirket",
                text_reseller_detail_page_user_info_address:"Adres",
                text_reseller_detail_page_user_info_password:"Şifre",
                text_reseller_detail_page_user_info_create_date:"Kayıt Tarihi",
                text_reseller_detail_page_user_info_credit:"Kredi",
                text_reseller_detail_page_user_info_new_credit:"Yeni Kredi",
                text_reseller_detail_page_user_info_add_credit:"Kredi Ekle",
                text_reseller_detail_page_user_info_del_credit:"Kredi Sil",
                text_reseller_detail_page_user_info_items:"Adet",
                text_reseller_detail_page_user_info_orders:"Sipariş",

                text_reseller_detail_page_user_table_tab_orders:"Siparişler",
                text_reseller_detail_page_user_table_tab_files:"Dosyalar",
                text_reseller_detail_page_user_table_tab_actives:"Aktiviteler",

                text_reseller_detail_page_user_table_title:"Bayi Aktiviteleri",
                text_reseller_detail_page_user_table_order_no:"Sipariş No",
                text_reseller_detail_page_user_table_name:"Ad Soyad",
                text_reseller_detail_page_user_table_order_quantity:"Sipariş Adeti",
                text_reseller_detail_page_user_table_total_credit:"Toplam Kredi",
                text_reseller_detail_page_user_table_global_total:"Toplam Tutar",
                text_reseller_detail_page_user_table_date:"Sipariş Tarihi",
                text_reseller_detail_page_user_table_status:"Durum",
                text_reseller_detail_page_user_table_setting:"Ayarlar",

                text_reseller_detail_page_user_table_files_no:"Dosya No",
                text_reseller_detail_page_user_table_files_size:"Dosya Boyutu",
                text_reseller_detail_page_user_table_files_type:"Dosya Tipi",
                text_reseller_detail_page_user_table_files_download_link:"İndirme Bağlantısı",
                text_reseller_detail_page_user_table_files_update_date:"Yükleme Tarihi",
                text_reseller_detail_page_user_table_files_update_time:"Yükleme Saati",
                text_reseller_detail_page_user_table_files_status:"Durum",
                text_reseller_detail_page_user_table_files_setting:"Ayarlar",


                text_reseller_order_table_order_status_finished:"TAMAMLANDI",
                text_reseller_order_table_order_status_canceled:"İPTAL EDİLDİ",
                text_reseller_order_table_order_status_pending:"BEKLİYOR",

                text_reseller_order_table_files_status_finished:"TAMAMLANDI",
                text_reseller_order_table_files_status_proccess:"İŞLENİYOR",
                text_reseller_order_table_files_status_canceled:"İPTAL EDİLDİ",
                text_reseller_order_table_files_status_pending:"BEKLİYOR",

                

               
               /* Reselelr Invoice Page */ 
               
               text_reseller_order_invoice_bradcom_navi_title:"Bayiler",
               text_reseller_order_invoice_bradcom_navi_pagename:"Siparişler",
               text_reseller_order_invoice_bradcom_navi_formname:"Fatura",

               text_reseller_order_invoice_mail:"Mail Gönder",
               text_reseller_order_invoice_print:"Yazdır",
               text_reseller_order_invoice_approve:"Onayla",
               text_reseller_order_invoice_invoice:"Fatura",
               text_reseller_order_invoice_invoice_no:"Fatura No",
               text_reseller_order_invoice_invoice_date:"Fatura Tarih",

               text_reseller_order_invoice_package_name:"Kredi Paket Adı",
               text_reseller_order_invoice_credit:"Kredi",
               text_reseller_order_invoice_quantity:"Adet",
               text_reseller_order_invoice_price:"Fiyat",
               text_reseller_order_invoice_total:"Toplam",
               text_reseller_order_invoice_suptotal:"TOPLAM",
               text_reseller_order_invoice_discount:"İNDİRİM",
               text_reseller_order_invoice_global_total:"GENEL TOPLAM",


               text_reseller_order_invoice_footer_description:"Yukarda belirtilen tutarı altta belirtilen banka hesabına gönderebilirsiniz. Lütfen fatura no'sunu transfer açıklamasına yazın.",
               text_reseller_order_invoice_bank_name:"Banka",
               text_reseller_order_invoice_bank_account_name:"Müşteri No",
               text_reseller_order_invoice_bank_account_code:"Hesap No",
               text_reseller_order_invoice_bank_iban:"IBAN",
               text_reseller_order_invoice_bank_branch_no:"Şube Kodu",
               text_reseller_order_invoice_bank_swift_code:"Swfit Kodu",
               text_reseller_order_invoice_bank_description:"Açıklama",

              
                  /* Bank Add and Edit Page */ 

                text_bank_add_end_edit_page_bradcom_navi_one:"Bankalar",
                text_bank_add_end_edit_page_bradcom_navi_two:"Banka Liste",
                text_bank_add_end_edit_page_bradcom_navi_tree:"Banka Ekleme ve Düzeltme",

                text_bank_add_end_edit_page_add_from_title:"Yeni Banka Ekle",
                text_bank_add_end_edit_page_edit_from_title:"Banka Güncelleme Formu",



                /* Credit Order Modal Confirm */ 

                text_credit_order_table_confirm_model_question:"Siparişi onaylamak istiyor musun?",
                text_credit_order_table_confirm_model_note:"Onayladıktan sonra geri iptal edebilirsiniz.",
                text_credit_order_table_confirm_model_okay_title:"Harika",
                text_credit_order_table_confirm_model_okay:"Tebrikler, sipariş onaylandı :)",
                text_credit_order_table_confirm_model_cancel_title:"İptal!", 
                text_credit_order_table_confirm_model_cancel:"Onaylama iptal edildi",               



                text_credit_order_table_delete_model_question:"Siparişi iptal etmek istiyor musun?",
                text_credit_order_table_delete_model_note:"İptal ettikten sonra geri onaylıya bilirsiniz",
                text_credit_order_table_delete_model_okay_title:"Maalesef",
                text_credit_order_table_delete_model_okay:"Patron, sipariş iptal edildi :(",
                text_credit_order_table_delete_model_cancel_title:"Durduruldu!", 
                text_credit_order_table_delete_model_cancel:"İptal etme durduruldu :)",  



                 /* Credit Order Page */ 



                 text_credit_order_page_bradcom_navi_title:"Siparişler",
                 text_credit_order_page_bradcom_navi_pagename:"Sipariş İşlemleri",
                 text_credit_order_page_bradcom_navi_endName:"Sipariş Listesi",
                 
                 text_credit_order_page_card_title:"Sipariş Listesi",

                text_credit_order_page_user_table_order_no:"Sipariş No",
                text_credit_order_page_user_table_name:"Ad Soyad",
                text_credit_order_page_user_table_order_quantity:"Sipariş Adeti",
                text_credit_order_age_user_table_total_credit:"Toplam Kredi",
                text_credit_order_page_user_table_global_total:"Toplam Tutar",
                text_credit_order_page_user_table_date:"Sipariş Tarihi",
                text_credit_order_page_user_table_status:"Durum",
                text_credit_order_page_user_table_setting:"Ayarlar",

                text_credit_order_table_order_status_finished:"TAMAMLANDI",
                text_credit_order_table_order_status_canceled:"İPTAL EDİLDİ",
                text_credit_order_table_order_status_pending:"BEKLİYOR",




                /* Services Modal Confirm */ 

                text_credit_order_table_services_model_question:"Hizmeti silmek istiyor musunuz?",
                text_credit_order_table_services_model_note:"Sildikten sonra geri alamazsınız",
                text_credit_order_table_services_model_okay_title:"Tamamlandı",
                text_credit_order_table_services_model_okay:"Hizmet Silindi",
                text_credit_order_table_services_model_cancel_title:"İptal!", 
                text_credit_order_table_services_model_cancel:"Silme işlemi iptal edildi",     


                /* Services Page*/ 


                text_services_page_bradcom_navi_title:"HizmetYönetimi",
                text_services_page_bradcom_navi_pagename:"Hizmet Ayarları",
                text_services_page_bradcom_navi_endName:"Hizmet Listesi",

                text_services_page_button_add_service:"Yeni Hizmet Ekle",
                text_services_page_card_title:"Hizmet Listesi",
                text_services_page_credit:"Kredi",
                text_services_page_discount:"İndirim",
                text_services_page_description:"Açıklama",
                text_services_page_date:"Oluşturma Tarihi",

                text_add_services_page_form_creare_form_title:"Yeni Hizmet Ekleme Formu",
                text_add_services_page_form_update_form_title:"Hizmet Güncelleme Formu",
                text_add_services_page_form_services_name:"Hizmet Adı",
                text_add_services_page_form_credit:"Hizmet Kredi Bedeli",
                text_add_services_page_form_discount:"İndirim",
                text_add_services_page_form_description:"Açıklama",
                




               /* Support Pages */   


                 text_page_support_create_support:"Destek Oluştur",
                 text_page_support_inbox:"Gelen",
                 text_page_support_sent:"Giden",
                 text_page_support_draft:"Taslak",
                 text_page_support_trash:"Çöp Kutusu",
                 text_page_support_Standart:"Standart",
                 text_page_support_Normaly:"Önemli",
                 text_page_support_Emegency:"Acil",
                 text_page_support_Private:"Özel",

                 text_page_support_select_all:"Tümünü Seç",
                 text_page_support_search_bar:"Arama",
                 text_page_support_labels:"Etiketler",
                 
                 text_page_support_form_title:"Yeni Destek Oluştur",
                 text_page_support_form_users:"Kullanıcılar",
                 text_page_support_form_subject:"Konu",
                 text_page_support_form_attacment:"Ekler",
                 text_page_support_form_item_not_found:"Harika. Buğün Sorunu olan yok :) ",
                 text_page_support_form_Answer:"Cevap",
              


                   text_log_title_file:" no'lu dosya'yı ",
                   text_log_title_file_two:" no'lu ",
                   text_log_description_files_insert:" göndermek üzere yükledi.",
                   text_log_description_files_update_one:"  dosya'yı gönderdi.",
                   text_log_description_files_update_two:" dosya'yı iptal etti.",

                   text_notifi_upload:"Dosya Yükledi",
                   text_notifi_send:"Dosya Gönderdi",
                   text_notifi_cancel:"Dosya İptel Etti ",

                 
                   text_notifi_title:"Dosya Bildirimleri",
                   text_notifi_new:"Yeni",
                   text_notifi_footer:"Tüm bldirimleri görüntüle",




                  /* Order Notification */ 


                 text_notifi_order_title:"Kredi Sipariş Bildirimleri",
                 text_notifi_order_new:"Yeni",
                 text_notifi_order_footer:"Tüm siparişleri görüntüle",

                 text_notifi_new_order_did:"Sipariş Verdi",
                 text_notifi_new_order:"no'lu siparişi oluşturdu.",




                 text_file_archives_page_bradcom_navi_title:"Dosya Yönetimi",
                 text_file_archives_page_bradcom_navi_pagename:"Arşivler",
                 text_file_archives_page_bradcom_navi_list:"Arşiv Listesi",

                }
                
              });
      
        };

     }
    
  
 

}




  };









  /*
  
  
  export const languageEN = () => ({
    type:actionTypes.LANGUAGE_EN,
    playload:{
        menu_text_dashboard:"Dashboard",
        menu_text_first:"First Menu",
        menu_text_second:"Second Menu",
    }

})


export const languageTR = () => ({
    type:actionTypes.LANGUAGE_TR,
    playload:{
        menu_text_dashboard:"Anasayfa",
        menu_text_first:"Birinci Menü",
        menu_text_second:"İkinci Menü",
    }

})
  
  */