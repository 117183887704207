import * as actionTypes from '../../actions/notification/actionTypes';
 
 
 const Notifications = (state=[],action) => {

    switch(action.type) {
    case actionTypes.NOTIFICATION:
      {return{
        Notifications:action.payload.data
      }
    

    }



      
    default: {
        return state
      }

    }

}



export default Notifications
