import * as actionTypes from '../../actions/notification/actionTypes';
 
 
 const OrderNotifications = (state=[],action) => {

    switch(action.type) {
    case actionTypes.ORDER_NOTIFICATION:
      {return{
        OrderNotifications:action.payload.data
      }
    

    }



      
    default: {
        return state
      }

    }

}



export default OrderNotifications
