import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { history } from "../../../history"
import LanguageButton from '../../../components/extra/Language/LanguageButton'

import {connect} from 'react-redux'




class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    langDropdown: true,
  }




  



  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  handleLangDropdown = () =>
  this.setState({ langDropdown: !this.state.langDropdown })



  Logout(){
    localStorage.removeItem('token')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')

    history.push('/')
  } 


  ThemeColor (){
    var theme= localStorage.getItem("theme")
    theme!=null ? theme=="dark" ? localStorage.setItem("theme", "light" ) : localStorage.setItem("theme", "dark" )  :  localStorage.setItem("theme", "dark" )  
    window.location.reload(false);
  }

  render() {

    var theme= localStorage.getItem("theme")
    const UserDropdown = (props) => {
      return (
        <DropdownMenu right>
          <DropdownItem onClick={() => history.push("/Profil")} >
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">Profil Ayarları</span>
          </DropdownItem>

          <DropdownItem onClick={() =>  this.ThemeColor() } >
            <Icon.RotateCw size={14} className="mr-50" />
      <span className="align-middle">{theme!=null ? theme=="dark" ? "Light Tasarım" : "Dark Tasarım"   :  "Dark Tasarım" }</span>
          </DropdownItem>
       
          <DropdownItem divider />
          <DropdownItem
            tag="a"
            href="#"
            onClick={() =>this.Logout()}
          >
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">Çıkış</span>
          </DropdownItem>
        </DropdownMenu>
      );
    };



    if(Array.isArray(this.props.Notification)) {
      
      var countfile = this.props.Notification.filter( item => item.file_status ==0 || item.file_status ==1 || item.file_status ==2  )
     
      var notificationRender = this.props.Notification.map((item,i) => {

        var date = item.creation_date.substr(0,10)
        var time = item.creation_time.substr(0,5)
        var customer = item.customer


        for (let index = 0; index < customer.length; index++) {
          var firstname = customer[index].firstname;
          var lastname  = customer[index].lastname;

       
          
        }
      
        return (
     
           item.file_status ==0 || item.file_status==1 || item.file_status ==2  ?
          <div key={i}  className="d-flex justify-content-between">
          <Media className="d-flex align-items-start">
            <Media left href="#"> 

       {
         item.file_status==1 ? <Icon.PlusSquare  className="font-medium-5 primary"  size={21}/>
         :  item.file_status==2 
         ? <Icon.Send  className="font-medium-5 success"  size={21}/>
         : item.file_status==0 
         ? <Icon.AlertOctagon  className="font-medium-5 danger"  size={21}/>
         : <div></div>
       }

            </Media>
            <Media body>
              <Media heading className={
                
                item.file_status==1 ? " media-heading primary " 
                :  item.file_status==2 
                ? " media-heading success "
                : item.file_status==0 
                ? " media-heading danger "
                : <div></div>

              
            
            }  tag="h6">
               {firstname +" "+ lastname +" " }   

               {

            item.file_status==1 ? this.props.Language.text_notifi_upload
            :  item.file_status==2 
            ? this.props.Language.text_notifi_send
            : item.file_status==0 
            ? this.props.Language.text_notifi_cancel
            : <div></div>

               }
              </Media>
              <p className="notification-text"> <b> {item.file_no}   </b>
              {item.log_description == "text_log_description_files_insert" && item.file_status==1 
              ? this.props.Language.text_log_title_file +" "+ this.props.Language.text_log_description_files_insert 
              : item.log_description == "text_log_description_files_insert" && item.file_status==2
              ? this.props.Language.text_log_title_file_two +" "+ this.props.Language.text_log_description_files_update_one
              : item.log_description == "text_log_description_files_insert" && item.file_status==0
              ? this.props.Language.text_log_title_file_two +" "+  this.props.Language.text_log_description_files_update_two 
              : <div></div>
              
              }
              </p>
            </Media>
            <small>
              <time
                className="media-meta"
                dateTime="2015-06-11T18:29:20+08:00"
              >
              {date}<br></br><br></br>
              {time}
              </time>
            </small>
          </Media>
        </div>   : <div key={i} ></div>
        )

      })
    

    }

 


    if(Array.isArray(this.props.OrderNotification)) {

      var countOrder = this.props.OrderNotification.filter( item => item.order_status ==0)
      var orderNotificationRender = this.props.OrderNotification.map((item,i) => {

        var date = item.creation_date.substr(0,10)
        var time = item.creation_time.substr(0,5)
        var customer = item.customer


        for (let index = 0; index < customer.length; index++) {
          var firstname = customer[index].firstname;
          var lastname  = customer[index].lastname;

       
          
        }
      
        return (
     
           item.order_status ==0  ?
          <div onClick={() => history.push("/OrderDetails/"+item.order_no)} key={i}  className="d-flex justify-content-between">
          <Media className="d-flex align-items-start">
            <Media left > 

       {
         item.order_status==0 ? <Icon.ShoppingCart  className="font-medium-5 success"  size={21}/>
       
         : <div></div>
       }

            </Media>
            <Media body>
              <Media heading className={
                
                item.order_status==0 ? " media-heading success " 
               
                : <div></div>

              
            
            }  tag="h6">
               {firstname +" "+ lastname +" " }   

               {

            item.order_status==0 ? this.props.Language.text_notifi_new_order_did
          
            : <div></div>






               }




              </Media>
              <p className="notification-text"> <b> {item.order_no}   </b>
              {item.order_status==0
              ? this.props.Language.text_notifi_new_order
             
              : <div></div>
              
              }
              </p>
            </Media>
            <small>
              <time
                className="media-meta"
                dateTime="2015-06-11T18:29:20+08:00"
              >
              {date}<br></br><br></br>
              {time}
              </time>
            </small>
          </Media>
        </div>   : <div key={i} ></div>
        )

      })
    

    }

  



    
    



  



    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

 

<LanguageButton />





        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Vuexy..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch : false })
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                ? item.email
                                : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                              ? item.date
                              : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }}
              />
            </div>
          </div>
        </NavItem>


        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.ShoppingBag size={21} />
            <Badge pill color="danger" className="badge-up">
              {" "}
              {Array.isArray(this.props.OrderNotification) ? countOrder.length : 0  } {" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media" >
            <li style={{backgroundColor:"#e74c3c"}} className="dropdown-menu-header ">
              <div  className="dropdown-header mt-0 ">
              <h3 className="text-white">{Array.isArray(this.props.OrderNotification) ? countOrder.length : 0  } {this.props.Language.text_notifi_new} </h3>
                <span className="notification-title">{this.props.Language.text_notifi_title}</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >

      
         {orderNotificationRender}

          
           




            </PerfectScrollbar>
            <li  onClick={() => history.push("/OrderNotification")}  className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">{this.props.Language.text_notifi_footer}</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>



        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              {Array.isArray(this.props.Notification) ? countfile.length : 0  } {" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
              <h3 className="text-white">{Array.isArray(this.props.Notification) ? countfile.length : 0  } {this.props.Language.text_notifi_new} </h3>
                <span className="notification-title">{this.props.Language.text_notifi_title}</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >

      
         {notificationRender}

          
           




            </PerfectScrollbar>
            <li onClick={() => history.push("/FileNotification")} className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">{this.props.Language.text_notifi_footer}</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
              {this.props.userName}
              </span>
              <span className="user-status">Müsait</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>

        {console.log(this.props.test)}
      </ul>
    )
  }
}




const mapStateToProps = (state) => {

  return { 
    Language:state.Language.LanguageReducers,
    Notification:state.Notifications.Notifications.Notifications,
    OrderNotification:state.Notifications.OrderNotifications.OrderNotifications,
  
  }
}


export default connect(mapStateToProps)(NavbarUser)
